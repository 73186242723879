import { LatLng } from 'leaflet'


import { ComponentLeafletMapContainer } from 'Component'
import {HookUrlParamMap, HookUrlParamZoom, HookLeafletZoom, HookUrlParamCenter, HookLeafletCenter } from 'Hook'

import {TypeLeafletTileType, TypeUrlParamControl } from 'Type'
import { PropChildren } from 'Prop'

type Props = PropChildren & {}

const ModulePageLeafletMap = ({children} :Props) => {
    const [zoom, setZoom] :TypeUrlParamControl<number> = HookUrlParamZoom()
    const [center, setCenter] :TypeUrlParamControl<LatLng> = HookUrlParamCenter()
    const [tileLayer, setTile] :TypeUrlParamControl<TypeLeafletTileType> = HookUrlParamMap()

    return <ComponentLeafletMapContainer center={center} zoom={zoom} tileLayer={tileLayer} scrollWheelZoom={true} doubleClickZoom={false}>
        <HookLeafletZoom setZoom={setZoom}/>
        <HookLeafletCenter center={center} setCenter={setCenter}/>
        {children}
    </ComponentLeafletMapContainer>

}

export default ModulePageLeafletMap