import { ComponentEuiStat } from 'Component'
import { TypeApiDistanceCoastPoint } from 'Type'
import { UtilMeasurement } from 'Util'

type Props = {
    point? :TypeApiDistanceCoastPoint
}

const formatAndConvert = (converter :(number :number) => number, number? :number) :string => {
    return (number) ? converter(number).toFixed(3).toString() : ''
}

const ModuleDisplayCoastPoint = ({point} :Props) => {
    return <>
        <ComponentEuiStat title={point && `${point.geometry.coordinates[1]}, ${point.geometry.coordinates[0]}`} description={'Lat, Lng'} titleElement={'span'} titleSize={'xxs'}/>
        <ComponentEuiStat isLoading={!point} title={`${formatAndConvert(UtilMeasurement.metersToMiles, point?.metersToCoast)} / ${formatAndConvert(UtilMeasurement.metersToKilometers, point?.metersToCoast)}`} description={'Distance to coast (mi / km)'} titleElement={'span'} titleSize={'xxs'}/>
    </>
}

export default ModuleDisplayCoastPoint