import { PropChildren } from 'Prop'
import { CSSProperties } from 'react'

type Props = PropChildren & {
    margin? :number
    zIndex? :number
    fullHeight? :true
    top? :number
}

const ComponentDivPositionAbsoluteRight = ({margin, zIndex, fullHeight, top, children} :Props) => {

    const marginPx :string = (margin) ? `${margin}px` : '0px'

    const style :CSSProperties = {
        position: 'absolute',
        float: 'right',
        right: 0,
        clear: 'both',
        marginTop: marginPx,
        marginRight: marginPx
    }

    if (zIndex) style.zIndex = zIndex
    if (top) style.top = `${top}px`
    if (fullHeight) style.height = `calc(100% - ${(top || 0) + (margin || 0) + (margin || 0)}px`

    return <div style={style}>{children}</div>
}

export default ComponentDivPositionAbsoluteRight