import { ComponentEuiButtonGroup } from 'Component'
import { HookUrlParamMap } from 'Hook'
import { RecordLeafletMapTile } from 'Record'
import {TypeEuiButtonGroupOption, TypeLeafletTile, TypeLeafletTileType } from 'Type'

const ModuleControlPageMap = () => {
    const [value, setMap] = HookUrlParamMap()

    const onChange = (id :string) => {
        setMap(id as TypeLeafletTileType)
    }


    const options :TypeEuiButtonGroupOption[] = Object.values(RecordLeafletMapTile).map((tile :TypeLeafletTile) => {
        return {
            id: tile.id,
            label: tile.label
        }
    })

    return <ComponentEuiButtonGroup legend={'Map Type'} options={options} idSelected={value} onChange={onChange} buttonSize={'s'} hasPadding={false}/>
}

export default ModuleControlPageMap