import { ComponentEuiHeader, ComponentEuiHeaderSection, ComponentEuiHeaderSectionItem } from 'Component'
import { ModuleControlSearchAddress } from 'Module'

const ModuleHeaderSecondary = () => {
    return <ComponentEuiHeader dark={false}>
        <ComponentEuiHeaderSection side={'left'} grow>
            <ComponentEuiHeaderSectionItem>{ <span style={{width: '10vw'}}></span> }</ComponentEuiHeaderSectionItem>
            <ComponentEuiHeaderSectionItem grow>
                <ModuleControlSearchAddress/>
            </ComponentEuiHeaderSectionItem>
            <ComponentEuiHeaderSectionItem>{ <span style={{width: '10vw'}}></span> }</ComponentEuiHeaderSectionItem>
        </ComponentEuiHeaderSection>
    </ComponentEuiHeader>
}

export default ModuleHeaderSecondary