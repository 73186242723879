import {ComponentEuiHeader, ComponentEuiHeaderSection, ComponentEuiHeaderSectionItem, ComponentMorphLogo, ComponentMorphTitle} from 'Component'

const ModuleHeaderPrimary = () => {
    return <ComponentEuiHeader dark>
        <ComponentEuiHeaderSection grow>
            <ComponentEuiHeaderSectionItem>
                <ComponentMorphLogo/>
            </ComponentEuiHeaderSectionItem>
            <ComponentEuiHeaderSectionItem>
                <ComponentMorphTitle/>
            </ComponentEuiHeaderSectionItem>
        </ComponentEuiHeaderSection>
    </ComponentEuiHeader>
}

export default ModuleHeaderPrimary