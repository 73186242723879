import {ReactNode} from 'react'
import {ComponentDivFlexGroup, ComponentDivFlexItem, ComponentDivMarginBottom,
    ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiPanel, ComponentEuiTitle } from 'Component'


type Props = {
    title :string
    width :number
    action? :ReactNode
    children :ReactNode
}

const ComponentDisplayPanel = ({title, width, action, children} :Props) =>
    <ComponentEuiPanel style={{width: `${width}px`}} hasBorder hasShadow={false}>
        <ComponentDivMarginBottom margin={-16}/>
        <ComponentEuiFlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
            <ComponentEuiFlexItem grow>
                <ComponentEuiTitle textTransform={'uppercase'} size={'xxs'}><p style={{textAlign: 'left'}}>{title}</p></ComponentEuiTitle>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                { action }
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup>
        {children}
    </ComponentEuiPanel>


export default ComponentDisplayPanel