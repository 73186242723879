import { EuiFlexGroup } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    alignItems? :'center' | 'baseline' | 'stretch' | 'flexStart' | 'flexEnd'
    component? :'div' | 'span'
    direction? :'row' | 'column' | 'rowReverse' | 'columnReverse'
    gutterSize? :'s' | 'none' | 'm' | 'xs' | 'l' | 'xl'
    justifyContent? :'center' | 'flexStart' | 'flexEnd' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly'
    responsive? :boolean
    wrap? :boolean
    children :ReactNode
}

const ComponentEuiFlexGroup = ({alignItems, component, direction, gutterSize, justifyContent, responsive, wrap, children} :Props) =>
    <EuiFlexGroup alignItems={alignItems} component={component} direction={direction} gutterSize={gutterSize}
                         justifyContent={justifyContent} responsive={responsive} wrap={wrap}>{ children }</EuiFlexGroup>


export default ComponentEuiFlexGroup