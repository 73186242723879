import { EuiButtonGroup } from '@elastic/eui'
import { TypeEuiButtonGroupOption } from 'Type'

import './ComponentEuiButtonGroup.css'

type Props = {
    buttonSize? :'s' | 'm' | 'compressed'
    isDisabled? :boolean
    isFullWidth? :boolean
    isIconOnly? :false
    legend :string
    color? :'text' | 'accent' | 'primary' | 'success' | 'warning' | 'danger'
    options :TypeEuiButtonGroupOption[]
    idSelected :string
    onChange :(id: string, value?: any) => void
    hasPadding? :boolean
}

const ComponentEuiButtonGroup = ({buttonSize, isDisabled, isFullWidth, isIconOnly, legend, color, options, idSelected, onChange, hasPadding = true} :Props) => {

    const className :string = `ComponentEuiButtonGroup${(!hasPadding) ? ' NoPadding' : ''}`

    return <EuiButtonGroup className={className} buttonSize={buttonSize} isDisabled={isDisabled}
                    isFullWidth={isFullWidth} isIconOnly={isIconOnly} legend={legend}
                    color={color} options={options} idSelected={idSelected} onChange={onChange}/>
}

export default ComponentEuiButtonGroup