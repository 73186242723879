
import {latLng, LatLng, latLngBounds } from 'leaflet'
import { PropChildren } from 'Prop'
import { MapContainer, TileLayer } from 'react-leaflet'

import { RecordLeafletMapTile } from 'Record'
import {TypeLeafletTile, TypeLeafletTileType } from 'Type'

import './ComponentLeafletMapContainer.css'

type Props = PropChildren & {
    center :LatLng
    zoom :number
    tileLayer :TypeLeafletTileType
    scrollWheelZoom? :boolean
    doubleClickZoom? :boolean
}

const ComponentLeafletMapContainer = ({center, zoom, tileLayer, scrollWheelZoom, doubleClickZoom, children} :Props) => {

    const tile :TypeLeafletTile = RecordLeafletMapTile[tileLayer]

    const corner1 = latLng(-90, -180)
    const corner2 = latLng(90, 180)
    const bounds = latLngBounds(corner1, corner2)

    return <MapContainer className={'LeafletMapContainer'} center={center} zoom={zoom} scrollWheelZoom={scrollWheelZoom} doubleClickZoom={doubleClickZoom} maxBounds={bounds}>
        <TileLayer
            attribution={''}
            url={tile.url}
            minZoom={4}
        />
        {children}
    </MapContainer>
}

export default ComponentLeafletMapContainer