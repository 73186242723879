import { useState } from 'react'
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import {TypeHttpControl, TypeHttpMethod, TypeHttpResponse } from 'Type'
import { UtilEnv } from 'Util';

const HookHttpClient = <Req = any, Res = any> (path :string) :TypeHttpControl<Req, Res> => {

    const join :string = (path.length > 0 && path.charAt(0) === '/') ? '' : '/'
    const url :string = `${UtilEnv.getEnvVar('api').replace(/\/$/, '')}${join}${path}`

    const [resp, setResp] = useState<TypeHttpResponse<Res> | undefined>(undefined)
    const [abortController, setAbortController] = useState<AbortController | undefined>(undefined)

    const setResponse = ({status, data} :AxiosResponse<any, any>) => { setResp({ status, data }) }

    const setErrorResponse = (err :AxiosError) => {
        const errResp :AxiosResponse<any, any> | undefined = err.response
        const data :any | undefined = errResp?.data
        const error :string = (data && data as string) ?? 'Unknown server error'
        const status :number = errResp?.status ?? 400
        setResp({error, status})
    }

    const buildRequest = (method :TypeHttpMethod, abortController :AbortController, data? :Req) :AxiosRequestConfig => ({method, url, signal: abortController.signal, data})

    const prepareAbortController = () :AbortController => {
        if (abortController) abortController.abort();
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        return newAbortController
    }

    const req = (method :TypeHttpMethod, data? :Req) => {
        setResp(undefined)
        const newAbortController :AbortController = prepareAbortController();
        axios.request(buildRequest(method, newAbortController, data)).then(setResponse).catch(setErrorResponse).finally(cleanUp)
    }

    const cleanUp = () => {
        setAbortController(undefined)
    }
    
    return {
        resp,
        loading: Boolean(abortController),
        req
    }
}

export default HookHttpClient