import { RecordEnv } from 'Record'
import { TypeEnv } from 'Type'

const getEnvVarOpt = (env :TypeEnv) :string | undefined => {
    return process.env[RecordEnv[env]]
}

const getEnvVar = (env :TypeEnv) :string => {
    const variable :string | undefined = getEnvVarOpt(env)
    if (!variable) throw new Error(`${env} env variable not found.`)
    return variable
}

export default {
    getEnvVarOpt,
    getEnvVar
}