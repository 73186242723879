import { EuiStat } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    description :ReactNode
    isLoading? :boolean
    reverse? :boolean
    textAlign? :'left' | 'right' | 'center'
    title :ReactNode
    titleColor? :string
    titleSize? :'s' | 'm' | 'xs' | 'l' | 'xxxs' | 'xxs'
    titleElement? :string
    descriptionElement? :string
}

const ComponentEuiStat = ({ description, isLoading, reverse, textAlign, title = '', titleColor, titleSize, titleElement, descriptionElement } :Props) =>
    <EuiStat description={description} isLoading={isLoading} reverse={reverse} textAlign={textAlign} title={title}
         titleColor={titleColor} titleSize={titleSize} titleElement={titleElement} descriptionElement={descriptionElement}/>

export default ComponentEuiStat