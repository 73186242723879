import { HookUrlParam } from 'Hook'
import { TypeUrlParamControl } from 'Type'
import { UtilParam } from 'Util'

const DEFAULT :string = '15'

const HookUrlParamZoom = () :TypeUrlParamControl<number> => {

    const [value, set] :TypeUrlParamControl = HookUrlParam('zoom', UtilParam.isParamNumberWithBoundsWrapper(2, 18), DEFAULT)

    return [+value, (newValue :number) => set(`${newValue}`)]
}

export default HookUrlParamZoom