import { LatLng } from 'leaflet'
import { useMapEvents } from 'react-leaflet'

type Props = {
    setLatLng: (zoom :LatLng) => void
}

const HookLeafletClickDouble = ({ setLatLng } :Props) => {

    useMapEvents({
        dblclick: (e) => setLatLng(e.latlng)
    });

    return null
}

export default HookLeafletClickDouble