import {EuiHeaderSection} from '@elastic/eui'
import {PropChildren} from 'Prop'

type Props = PropChildren & {
    side? :'left' | 'right'
    grow?: boolean
}

const ComponentEuiHeaderSection = ({ side, grow, children }: Props) => {
    return <EuiHeaderSection side={side} grow={grow}>{children}</EuiHeaderSection>
}

export default ComponentEuiHeaderSection