import { HookUrlParam } from 'Hook'
import { RecordLeafletMapTile } from 'Record'
import {TypeLeafletTileType, TypeUrlParamControl } from 'Type'

const validate = (param :string) :boolean => {
    const maps :TypeLeafletTileType[] = Object.keys(RecordLeafletMapTile) as TypeLeafletTileType[]
    for (let i = 0; i < maps.length; i++) if (maps[i] === param) return true;
    return false
}

const DEFAULT :TypeLeafletTileType = 'def'

const HookUrlParamMap = () :TypeUrlParamControl<TypeLeafletTileType> => {

    const [map, setMap] :TypeUrlParamControl = HookUrlParam('map', validate, DEFAULT)

    const value :TypeLeafletTileType = map as TypeLeafletTileType

    return [value, setMap]
}

export default HookUrlParamMap