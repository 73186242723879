
const metersToKilometers = (meters :number) :number => {
    return meters / 1000
}

const metersToMiles = (meters :number) :number => {
    return meters / 1609.344
}

export default {
    metersToKilometers,
    metersToMiles
}