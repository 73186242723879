import {useEffect, useState} from 'react'

import { HookHttpClient } from 'Hook'
import {TypeApiAddress, TypeApiAddressSearch, TypeHttpControl } from 'Type'

const HookApiAddressSearch = () :[TypeApiAddressSearch[] | undefined, boolean, (search :string | undefined) => void] => {

    const searchCtl :TypeHttpControl<TypeApiAddress, TypeApiAddressSearch[]> = HookHttpClient('/location/search')

    const [search, setSearch] = useState<string | undefined>()

    useEffect(() => {
        if (search && search.length > 2) searchCtl.req('post', {address: search})
    }, [search])

    const safeSetSearch = (newSearch :string | undefined) => {
        if (newSearch !== search) setSearch(newSearch)
    }

    return [searchCtl.resp?.data, searchCtl.loading, safeSetSearch]
}

export default HookApiAddressSearch