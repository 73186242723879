import {CSSProperties} from 'react'
import { EuiPanel } from '@elastic/eui'
import { PropChildren } from 'Prop'

type Props = PropChildren & {
    style? :CSSProperties
    hasShadow? :boolean
    hasBorder? :boolean
    paddingSize? :'none' | 's' | 'm' | 'xs' | 'l' | 'xl'
    borderRadius? : 'none' | 'm'
    grow? :boolean
    color? :'transparent' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain'

}

const ComponentEuiPanel = ({style, hasShadow, hasBorder, paddingSize, borderRadius, grow, color, children} :Props) =>
    <EuiPanel style={style} hasShadow={hasShadow} hasBorder={hasBorder} paddingSize={paddingSize} borderRadius={borderRadius} grow={grow} color={color}>{children}</EuiPanel>

export default ComponentEuiPanel