import { EuiComboBox } from '@elastic/eui'
import {TypeEuiComboBoxOption} from 'Type'

type Props = {
    className? :string
    async? :boolean
    compressed? :boolean
    fullWidth? :boolean
    isClearable? :boolean
    isDisabled? :boolean
    isInvalid? :boolean
    isLoading? :boolean
    noSuggestions? :boolean
    onChange? :(options :TypeEuiComboBoxOption[]) => void
    onSearchChange? :(searchValue: string, hasMatchingOptions?: boolean) => void
    placeholder? :string
    rowHeight? :number
    singleSelection? :boolean
    delimiter? :string
    options? :TypeEuiComboBoxOption[]
}

const ComponentEuiComboBox = ({className, async, compressed, fullWidth, isClearable, isDisabled, isInvalid, isLoading, noSuggestions,
                                  onChange, onSearchChange, placeholder, rowHeight, singleSelection, delimiter, options} :Props) =>
    <EuiComboBox className={className} async={async} compressed={compressed} fullWidth={fullWidth} isClearable={isClearable} isDisabled={isDisabled}
                 isInvalid={isInvalid} isLoading={isLoading} noSuggestions={noSuggestions} onChange={onChange} onSearchChange={onSearchChange}
                 placeholder={placeholder} rowHeight={rowHeight} singleSelection={singleSelection} delimiter={delimiter} options={options}/>

export default ComponentEuiComboBox