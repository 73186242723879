import { ComponentEuiButtonIcon } from 'Component'
import {LatLng} from 'leaflet'

type Props = {
    coord :LatLng
    target :LatLng
    setLatLng: (zoom :LatLng) => void
}

const ModuleControlPagePoint = ({ coord, target, setLatLng } :Props) => {

    const isDisabled :boolean = coord.equals(target)

    const onClick = () => {
        setLatLng(target)
    }

    return <ComponentEuiButtonIcon ariaLabel={'Center on Address'} display={'fill'} iconType={'crosshairs'} color={'primary'} isDisabled={isDisabled} onClick={onClick}/>
}

export default ModuleControlPagePoint