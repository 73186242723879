import {LatLng} from 'leaflet'
import {useEffect, useState} from 'react'
import { TypeApiDistanceCoastAddress, TypeHttpControl } from 'Type'
import {HookHttpClient} from 'Hook'

const HookApiDistanceCoastAddress = (coord :LatLng) :TypeApiDistanceCoastAddress | undefined => {

    const distCtl :TypeHttpControl<LatLng, TypeApiDistanceCoastAddress> = HookHttpClient('/point/address')

    const [req, setReq] = useState<LatLng | undefined>()

    useEffect(() => {
        if (!req || !coord.equals(req)) fetch(coord)
    }, [coord.toString()])

    const fetch = (coord :LatLng) => {
        setReq(coord)
        distCtl.req('post', coord)
    }

    return distCtl.resp?.data
}

export default HookApiDistanceCoastAddress