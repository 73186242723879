import { TypeUrlParam } from 'Type'

type RecordUrlParams = Record<TypeUrlParam, string[]>

const isParamNumberWithBoundsWrapper = (min :number, max :number) :(param :string) => boolean => {
    return (param :string) => {
        const num = parseFloat(param)
        if (isNaN(num) || num < min || num > max) return false
        return true
    }
}

const getSearchParams = () :URLSearchParams => {
    return new URLSearchParams(document.location.search)
}

const updateSearchHistory = (urlSearchParams :URLSearchParams) :void => {
    window.history.replaceState(null, '', '?'+urlSearchParams.toString());
}

const setSearchParam = (urlSearchParams :URLSearchParams, param :TypeUrlParam, values :string[]) => {
    urlSearchParams.delete(param)
    values.forEach((value :string) => {urlSearchParams.append(param, value)})
}

const setAllSearchParam = (urlSearchParams :URLSearchParams, allParams :Partial<RecordUrlParams>) => {
    const keys :TypeUrlParam[] = Object.keys(allParams) as TypeUrlParam[]
    for (let i = 0; i < keys.length; i++) {
        const k :TypeUrlParam = keys[i]
        setSearchParam(urlSearchParams, k, allParams[k] || [])
    }
}

export default {
    isParamNumberWithBoundsWrapper,
    getSearchParams,
    updateSearchHistory,
    setSearchParam,
    setAllSearchParam
}