import { EuiFlexItem } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    grow? :boolean | 0 | 1 | 2 | 3 | 4 | 6 | 10 | 7 | 8 | 5 | 9
    children? :ReactNode
}

const ComponentEuiFlexItem = ({grow = 0, children} :Props) =>
    <EuiFlexItem grow={grow}>{ children }</EuiFlexItem>

export default ComponentEuiFlexItem