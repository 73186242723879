import { HookHttpClient } from 'Hook/index'
import { LatLng } from 'leaflet'
import {useEffect, useState} from 'react'
import {TypeApiDistanceCoastPoint, TypeHttpControl } from 'Type'


const HookApiDistanceCoastPoint = (coord :LatLng) :TypeApiDistanceCoastPoint | undefined => {
    
    const distCtl :TypeHttpControl<LatLng, TypeApiDistanceCoastPoint> = HookHttpClient('/point/coast')

    const [req, setReq] = useState<LatLng | undefined>()

    useEffect(() => {
        if (!req || !coord.equals(req)) fetch(coord)
    }, [coord.toString()])

    const fetch = (coord :LatLng) => {
        setReq(coord)
        distCtl.req('post', coord)
    }
    
    return distCtl.resp?.data
}

export default HookApiDistanceCoastPoint