import {EuiHeader} from '@elastic/eui'
import {PropChildren} from 'Prop'

type Props = PropChildren & {
    dark? :boolean
    boxShadow?: boolean
}

const ComponentEuiHeader = ({ dark, boxShadow = false, children }: Props) => {
    const style: any = {}
    if (!boxShadow) style.boxShadow = 'none'
    if (!dark) style.background = '#F7F8FC'
    return <EuiHeader theme={(dark) ? 'dark' : 'default'} style={style}>{children}</EuiHeader>
}

export default ComponentEuiHeader