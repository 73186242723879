import { PropChildren } from 'Prop'
import {CSSProperties} from 'react'

type Props = PropChildren & {
    flexGrow? :number
    direction? :'row' | 'column'
}

const ComponentDivFlexItem = ({ flexGrow, children } :Props) => {
    const style :CSSProperties = {
    }

    if (flexGrow) style.flexGrow = flexGrow

    return <div style={style}>
        { children }
    </div>
}

export default ComponentDivFlexItem