
import { LatLng } from 'leaflet'
import { PropChildren } from 'Prop'
import {Marker, Popup} from 'react-leaflet'

import './Marker.css'


type Props = PropChildren & {
    coord :LatLng
}

const ComponentLeafletMarker = ({ coord, children } :Props) => {

    return <Marker position={ coord }>
        <Popup>{ children }</Popup>
    </Marker>
}

export default ComponentLeafletMarker