import {EuiButtonIcon, IconType} from '@elastic/eui'

type Props = {
    ariaLabel :string
    iconType :IconType
    color? :'text' | 'accent' | 'primary' | 'success' | 'warning' | 'danger'
    isDisabled? :boolean
    size? :'s' | 'm' | 'xs'
    iconSize? :'s' | 'm' | 'l' | 'xl' | 'original' | 'xxl'
    isSelected? :boolean
    display? :'base' | 'fill' | 'empty'
    isLoading? :boolean
    onClick? :() => void
}

const ComponentEuiButtonIcon = ({ariaLabel, iconType, color, isDisabled, size, iconSize, isSelected, display, isLoading, onClick} :Props) =>
    <EuiButtonIcon style={{border: 0}} iconType={iconType} color={color} isDisabled={isDisabled} size={size} iconSize={iconSize}
                   isSelected={isSelected} display={display} isLoading={isLoading} onClick={onClick} aria-label={ariaLabel}/>

export default ComponentEuiButtonIcon