import { Point } from 'geojson'
import {latLng, LatLng} from 'leaflet'

const LAT_LNG_DELIMITER :string = '_'

const pointToLatLng = (point :Point) :LatLng => {
    return latLng(point.coordinates[1], point.coordinates[0])
}

const stringToLatLng = (latLngStr :string) :LatLng => {
    const parts :string[] = latLngStr.split(LAT_LNG_DELIMITER)
    return latLng(parseFloat(parts[0]), parseFloat(parts[1]))
}

const latLngToString = (latLng :LatLng) :string => `${latLng.lat}${LAT_LNG_DELIMITER}${latLng.lng}`

const isNumAndWithinBounds = (num :number, min :number, max :number) :boolean => {
    if (isNaN(num) || num < min || num > max) return false
    return true
}

const isValidLatLngStr = (latLngStr :string) :boolean => {
    const parts :number[] = latLngStr.split(LAT_LNG_DELIMITER).map(parseFloat)
    if (parts.length != 2) return false
    const lat :number = parts[0]
    const lng :number = parts[1]
    return (isNumAndWithinBounds(lat, -90, 90) && isNumAndWithinBounds(lng, -180, 180))
}

export default {
    pointToLatLng,
    stringToLatLng,
    latLngToString,
    isValidLatLngStr
}