import {useMapEvents} from 'react-leaflet'

type Props = {
    setZoom: (zoom :number) => void
}

const HookLeafletZoom = ({ setZoom } :Props) => {

    useMapEvents({
        zoom: (e) => {
            setZoom(e.target.getZoom())
        }
    });
    return null
}

export default HookLeafletZoom