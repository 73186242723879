import { EuiTitle } from '@elastic/eui'
import { ReactElement } from 'react'

type Props = {
    size? : 's' | 'm' | 'xs' | 'l' | 'xxxs' | 'xxs'
    textTransform? :'uppercase'
    children :ReactElement
}

const ComponentEuiTitle = ({ size, textTransform, children } :Props) =>
    <EuiTitle size={ size } textTransform={ textTransform }>{ children }</EuiTitle>

export default ComponentEuiTitle