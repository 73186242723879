import { useEffect } from 'react'
import {useLocation, useNavigate } from 'react-router-dom'
import {TypeUrlParam, TypeUrlParamControl} from 'Type'
import { UtilParam } from 'Util'


const HookUrlParam = (param :TypeUrlParam, isValid :(val :string) => boolean, defaultVal :string) :TypeUrlParamControl => {

    const navigate = useNavigate()
    const location = useLocation()

    const paramValue :string | undefined = new URLSearchParams(location.search).get(param) || undefined

    const value :string = (paramValue && isValid(paramValue)) ? paramValue : defaultVal

    useEffect(() => {
        if (!paramValue) setUrlParam(value)
    }, [paramValue])

    const setUrlParam = (newValue :string) => {
        const urlSearchParams :URLSearchParams = UtilParam.getSearchParams()
        urlSearchParams.set(param, newValue)
        UtilParam.updateSearchHistory(urlSearchParams)
        navigate({search: urlSearchParams.toString()})
    }

    return [value, setUrlParam]
    
}

export default HookUrlParam