import { EuiPage } from '@elastic/eui'
import { PropChildren } from 'Prop'
import {CSSProperties} from "react";

type Props = PropChildren & {
    headerHeight? :number
    paddingSize? :'none' | 's' | 'm' | 'xs' | 'l' | 'xl'
    grow? :boolean
    direction? :'row' | 'column'
    restrictWidth? :string | number | boolean
}

const ComponentEuiPage = ({headerHeight, paddingSize, grow, direction, restrictWidth, children} :Props) => {

    const style :CSSProperties = {}
    style.height = (headerHeight) ? `calc(100% - ${headerHeight}px)` : '100%'

    return <EuiPage style={style} paddingSize={paddingSize} grow={grow} direction={direction}
             restrictWidth={restrictWidth}>{children}</EuiPage>
}
export default ComponentEuiPage