import { PropChildren } from 'Prop'
import { CSSProperties } from 'react'

type Props = PropChildren & {
    overflow? :string
}

const ComponentDiv = ({overflow, children} :Props) => {

    const style :CSSProperties = {width: '100%', height: '100%'}
    if (overflow) style.overflow = overflow
    return <div style={style}>{children}</div>
}

export default ComponentDiv