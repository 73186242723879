import { ComponentEuiStat } from 'Component'
import { TypeApiDistanceCoastAddress } from 'Type'

type Props = {
    point? :TypeApiDistanceCoastAddress
}

const ModuleDisplayCoastAddress = ({point} :Props) => {
    const isLoading = !point
    return <>
        <ComponentEuiStat isLoading={isLoading} title={point?.formatted ?? '-'} description={'Formatted'} titleElement={'span'} titleSize={'xxs'}/>
    </>
}

export default ModuleDisplayCoastAddress