import { PropChildren } from "Prop"
import {CSSProperties} from "react";

type Props = PropChildren & {
    fullHeight? :boolean
    direction? :'row' | 'column'
}

const ComponentDivFlexGroup = ({ fullHeight, direction = 'row', children } :Props) => {

    const style :CSSProperties = {
        display: 'flex',
        flexDirection: direction
    }

    if (fullHeight) style.height = '100%'

    return <div style={style}>
        { children }
    </div>
}

export default ComponentDivFlexGroup