import { latLng, LatLng } from 'leaflet'
import { TypeUrlParamControl } from 'Type'
import { HookUrlParam } from 'Hook'
import {UtilGeo } from 'Util'


const DEFAULT :LatLng = latLng(40.752655, -73.977295)

const HookUrlParamPoint = () :TypeUrlParamControl<LatLng> => {

    const [valueStr, setPoint] :TypeUrlParamControl = HookUrlParam('point', UtilGeo.isValidLatLngStr, UtilGeo.latLngToString(DEFAULT))

    const value :LatLng = UtilGeo.stringToLatLng(valueStr)

    const set = (point :LatLng) => {
        setPoint(UtilGeo.latLngToString(point))
    }

    return [value, set]
}

export default HookUrlParamPoint