import { EuiBottomBar } from '@elastic/eui'
import { PropChildren } from 'Prop'
import {CSSProperties} from "react";

type Props = PropChildren & {
    affordForDisplacement? :boolean
    position? :'fixed'
    paddingSize? :'none' |'s' | 'm' | 'l'
    bodyClassName? :string
    landmarkHeading? :string
    top? :string | number
    right? :string | number
    bottom? :string | number
    left? :string | number
    padding? :number
}

const ComponentEuiBottomBar = ({ affordForDisplacement, position, paddingSize, bodyClassName, landmarkHeading, top, right, bottom, left, padding, children } :Props) => {
    const style :CSSProperties = {}
    if (padding) style.padding = `${padding}px`
    return <EuiBottomBar style={style} affordForDisplacement={affordForDisplacement} position={position} paddingSize={paddingSize}
         bodyClassName={bodyClassName} landmarkHeading={landmarkHeading} top={top} right={right} bottom={bottom} left={left}>{children}</EuiBottomBar>
}

export default ComponentEuiBottomBar