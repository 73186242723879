import {LatLng} from 'leaflet'
import {useMap, useMapEvents} from 'react-leaflet'

type Props = {
    center :LatLng
    setCenter :(zoom :LatLng) => void
}

const HookLeafletCenter = ({center, setCenter} :Props) => {
    const map = useMap();
    if (!map) throw new Error('This hook must be used within a Leaflet MapContainer')

    const isOffCenter = (latLng :LatLng) :boolean => !center.equals(latLng)

    if (isOffCenter(map.getCenter())) map.setView(center);
    useMapEvents({
        dragend: (e) => {
            if (isOffCenter(e.target.getCenter())) setCenter(e.target.getCenter());
        },
        zoom: (e) => {
            setCenter(e.target.getCenter())
        }
    });

    return null
}

export default HookLeafletCenter