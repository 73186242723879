import React from 'react'
import { EuiHeaderSectionItem } from '@elastic/eui'
import { PropChildren } from 'Prop'

import './ComponentEuiHeaderSectionItem.css'

type Props = PropChildren & {
    grow? :boolean
}

const ComponentEuiHeaderSectionItem = ({ grow, children }: Props) => {
    const className :string | undefined = (grow) ? 'ComponentEuiHeaderSectionItemGrow' : undefined
    return <EuiHeaderSectionItem className={className}>{children}</EuiHeaderSectionItem>
}

export default ComponentEuiHeaderSectionItem