import { Point, GeoJsonObject } from 'geojson'
import { LatLng } from 'leaflet'
import { GeoJSON } from 'react-leaflet'
import { ComponentLeafletMarker } from 'Component'
import { UtilGeo } from 'Util'
import { PropChildren } from 'Prop'

type Props = PropChildren & {
    coord :LatLng | Point
    coast? :GeoJsonObject
    distance? :GeoJsonObject
    coastColor :string
    distanceColor :'red' | 'blue' | 'green'
}

const ComponentPointCoast = ({coord, coast, distance, coastColor, distanceColor, children} :Props) => {


    const innerCoord :LatLng = (coord instanceof LatLng) ? coord : UtilGeo.pointToLatLng(coord as Point)

    return <>
        <ComponentLeafletMarker coord={innerCoord}>{ children }</ComponentLeafletMarker>
        {coast && <GeoJSON data={coast} style={{color: coastColor}}/>}
        {distance && <GeoJSON data={distance} style={{color: distanceColor}}/>}
    </>
}

export default ComponentPointCoast