import { ComponentEuiComboBox } from 'Component'
import { HookApiAddressSearch, HookUrlParamCenter, HookUrlParamPoint } from 'Hook'
import { LatLng } from 'leaflet'
import { TypeApiAddressSearch, TypeEuiComboBoxOption } from 'Type'
import { UtilGeo } from 'Util'
import './ModuleControlSearchAddress.css'

type Props = {

}

const ModuleControlSearchAddress = ({} :Props) => {

    const [resp, loading, setSearch] :[TypeApiAddressSearch[] | undefined, boolean, (search :string | undefined) => void] = HookApiAddressSearch()
    const [point, setPoint] = HookUrlParamPoint()
    const [center, setCenter] = HookUrlParamCenter()

    const toOpt = (search :TypeApiAddressSearch) :TypeEuiComboBoxOption => {
        return {label: search.formatted}
    }

    const options :TypeEuiComboBoxOption[] | undefined = resp && resp.map(toOpt)

    const findSearch = (formatted :string) :TypeApiAddressSearch | undefined => {
        if (!resp) return
        for (let i = 0; i < resp.length; i++) if (resp[i].formatted === formatted) return resp[i]
    }

    const onChange = (options :TypeEuiComboBoxOption[]) => {
        if (options.length === 0) return
        const search :TypeApiAddressSearch | undefined = findSearch(options[0].label)
        if (!search) return
        const latLng :LatLng = UtilGeo.pointToLatLng(search.geometry);
        setPoint(latLng)
        setCenter(latLng)
        setSearch(undefined)
    }

    return <ComponentEuiComboBox fullWidth
                                 async
                                 singleSelection
                                 className={'ModuleControlSearchAddress'}
                                 placeholder={'Search for a location'}
                                 onSearchChange={setSearch}
                                 onChange={onChange}
                                 isLoading={loading}
                                 options={options}/>
}

export default ModuleControlSearchAddress